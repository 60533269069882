// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlfjminSdkv97D6Qafo-58il6mY6EnV9U",
  authDomain: "roulette-6afb2.firebaseapp.com",
  projectId: "roulette-6afb2",
  storageBucket: "roulette-6afb2.appspot.com",
  messagingSenderId: "616534077676",
  appId: "1:616534077676:web:3add6671d1d864e51235c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const ItemsColl = collection(db, 'Items')
