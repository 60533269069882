import React, { useEffect } from 'react'
import Wheel from './components/Wheel'
import gifts from './images/imagebage.png'
import backgroundImage from './images/bg.jpeg';
import WheelGame from './components/WheelGame';


const Home = () => {

  useEffect(() => {
    const setData = () => {
      const info = JSON.parse(localStorage.getItem('roul'))
      const array = Array.from({ length: 4 }, () => null);
      if(info) {
      //console.log(info?.res)
      if(info?.res?.indexOf(null) !== -1){
        console.log('cc')
      }
      else {
        localStorage.setItem("roul", JSON.stringify({
          res: array,
          speaker: null
        }))
      }
    }
    else {
      localStorage.setItem('roul', JSON.stringify({
        res: array,
        speaker: null
      }))
    }
    }
    setData()
  }, [])
  return (
    <div
      className="bg-cover md:bg-contain bg-center h-screen"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
    <div className="flex md:flex-row flex-col md:justify-between px-10 h-screen overflow-x-hidden" >
    <div className="md:mt-24 mt-12 flex-col items-center" >
    <h2 className="font-black md:text-5xl text-4xl text-center text-white" >BALLANTINE'S X J.DEMSKY</h2>
    <h2 className="my-14 text-center md:text-5xl text-4xl font-black text-white" >Tourne la roue <br />
  et découvre ton gain !</h2>
    <img src={gifts} className="md:h-52 h-40 mx-auto"  />
    </div>
    <WheelGame />
    
    {/**md:h-[28vh] md:w-[28vw] md:mt-24 mt-6 */}
  </div>
  {/**<h4 className="text-white text-end mt-6 font-bold" >Drink responsibly</h4> */}
  </div>
  )
}

export default Home