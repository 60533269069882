import { doc, getDocs, query, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { ItemsColl, db } from './config/firebase'
import { useNavigate } from 'react-router-dom'

const Dash = () => {
    let navigate = useNavigate()
    const [items, setItems] = useState(null)
    const [bags, setBags] = useState(0)
    const [speakes, setSpeakes] = useState(0)
    const [qty, setQty] =  useState(0)

    const handleUpdate = async () => {
        try {
            await updateDoc(doc(db, 'Items', items?.id), {
                bags: Number(bags),
                speakers: Number(speakes),
                qty: Number(qty)
            })
            navigate('/')
        } catch (err) {
            
        }
    }

    useEffect(() => {
        const getData = async () => {
          const qDocs = await getDocs(query(ItemsColl))
          const data = qDocs.docs.map(d => ({...d.data(), id: d.id}))
          setItems(data[0])
          setBags(data[0]?.bags)
          setSpeakes(data[0]?.speakers)
          setQty(data[0]?.qty)
          localStorage.setItem('qty', Number(data[0]?.qty))
        }
        getData()
    }, [])
  return (
    <div className='min-h-screen flex flex-1 items-center justify-center' >
        <div className='w-1/3' >
            <h2 className='font-black text-4xl text-center mb-6' >Roulette Control</h2>
        <label>Bags Percentage</label>
        <input type="number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
        value={bags}
        onChange={e => setBags(e.target.value)}
        />
        <label>Speakers Percentage</label>
        <input type="number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
        value={speakes}
        onChange={e => setSpeakes(e.target.value)}
        />
        <label>Number Of Spins</label>
        <input type="number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
        value={qty}
        onChange={e => setQty(e.target.value)}
        />
        <button
        onClick={handleUpdate}
        className='w-full py-3 text-white text-center font-bold bg-green-500 mt-6 rounded-lg' >
            Appliquer
        </button>
        </div>
    </div>
  )
}

export default Dash