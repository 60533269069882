import React from "react";
import Wheel from "./components/Wheel";
import "./App.css";
import gifts from './images/imagebage.png'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dash from "./Dash";
import Home from "./Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/ytQ94J76z9WlY3",
    element: <Dash />
  }
]);

const App = () => {
  return (
    <RouterProvider  router={router} />
  );
};

export default App;
