import React, { useEffect, useState } from 'react';
import './WheelGame.css';
import Wheel from '../images/rol.png'
import roll from '../images/roll.mp3'
import done from '../images/done.mp3'
import bag from '../images/totebag.png'
import speaker from '../images/speaker.png'
import sign from '../images/sign.png'
import point from "../images/point.png";

const sections = [
    { start: 0, end: 45, result: 'Tote Bag', img: bag },
    { start: 45, end: 90, result: 'Speaker', img: speaker },
    { start: 90, end: 135, result: 'Tote Bag', img: bag },
    { start: 135, end: 180, result: 'Speaker', img: speaker },
    { start: 180, end: 225, result: 'Tote Bag', img: bag },
    { start: 225, end: 270, result: 'Speaker', img: speaker },
    { start: 270, end: 315, result: 'Tote Bag', img: bag },
    { start: 315, end: 360, result: 'Speaker', img: speaker },
  ];

const WheelGame = () => {
  const [spinning, setSpinning] = useState(false);
  const [nbr, setNbr] = useState(1)
  const [show, setShow] = useState(false)
  const [audio] = useState(new Audio(roll))
  const [aud] = useState(new Audio(done))
  const [result, setResult] = useState(null)
  const [start, setStart] = useState(false)

  const spinWheel = () => {
    console.log(spinning)
    if(spinning) return
    audio.play()
    let targetSection = 0
    if(nbr <= 4) {
        targetSection = 0
    }
    else {
        targetSection = 1
    }
     // The desired section to stop at (0-based index)
  
    const sectionAngle = 360 / sections.length;
    const targetAngle = sectionAngle * targetSection;
    
  
    setSpinning(true);
    setStart(!start)
    const wheel = document.getElementById('wheel');
    wheel.style.transition = 'none'; // Reset transition
    wheel.style.transform = `rotate(${targetAngle}deg)`;

    setTimeout(() => {
      wheel.style.transition = 'transform 5s ease-in-out'; // Apply transition
      wheel.style.transform = `rotate(${targetAngle + 360 * 5}deg)`; // Spin the wheel multiple times
    }, 100);
  };


  const handleClose = () => {
    setShow(false)  
    setSpinning(false)
  }
/*
  useEffect(() => {
    if (spinning) {
      const timer = setTimeout(() => {
        audio.pause()
        aud.play()
        const targetSection = nbr !== 4 ? 0 : 1; // The desired section to stop at (0-based index)
        setResult({
            img: sections[targetSection].img,
            res: sections[targetSection].result
        });
        //console.log(sections[targetSection].result)
        setShow(true)
      }, 5000);

      return () => clearTimeout(timer);
    }
    else {
        setResult(null)
    }
  }, [start]);

  */

  const onTransitionEnd = () => {
    audio.pause()
    audio.currentTime = 0
        aud.play()
        let targetSection = 0
        if(nbr <= 4) {
            targetSection = 0
            setNbr(nbr => nbr+1)
        }
        else {
            targetSection = 1
            setNbr(1)
        }
        setResult({
            img: sections[targetSection].img,
            res: sections[targetSection].result
        });
        //console.log(sections[targetSection].result)
        setShow(true)
  }

  return (
    <>
    <div className="flex flex-col items-center md:h-[36vh] md:w-[36vw] md:mt-24 mt-6" >
      <div className="relative flex items-center">
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none"></div>
        <div className="relative">
          <img src={point} className="md:h-28 md:w-24 w-16 absolute md:top-[0.3%] z-50 top-[-1%] right-[41%] md:right-[42%]" />
          <img src={Wheel} onClick={spinWheel} id="wheel" alt="Wheel" onTransitionEnd={onTransitionEnd} className="mx-auto" />
        </div>
      </div>
    </div>

    {show && (
      <div>
        <div className="reward bg-[#1C183D]">
          <p className="absolute bottom-5 right-5 font-bold text-white">Drink responsibly</p>
          <img src={sign} className="absolute bottom-5 left-5 md:h-24 h-16" />

          <>
            <img src={result?.img} alt={result?.img} className="md:h-60 h-40 mx-auto" />
            <p className="font-extrabold md:text-3xl text-xl text-center text-white">
              {`Félicitations ! Vous avez gagné un ${result?.res === 'Tote Bag' ? 'Tote Bag' : 'Speaker'}`}
            </p>
          </>

          <button className="btn-close" onClick={handleClose}>
            Retourner à la roue
          </button>
        </div>
        <div className="mask" />
      </div>
    )}
  </>
  );
};

export default WheelGame;
